@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.headline-figure {
  margin-bottom: $spacer;

  .top-line {
    align-items: center;
    display: flex;
    font-size: $h2-font-size;
    justify-content: space-between;
  }

  .total-stat {
    font-size: $h2-font-size;
    font-weight: $font-weight-bold;
    margin-bottom: 0;

    .denominator {
      opacity: 0.8;
      font-weight: $font-weight-normal;
    }
  }

  .stat-icon {
    height: 1em;
  }

  .lnr,
  .mdi-icon {
    height: 1em;
    opacity: 0.8;
    vertical-align: top;
    width: 1em;
  }

  .subhead {
    color: $text-color-medium;
    font-size: rem(11);
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }
}
