// Progress bars

$progress-height: 1.5em;
$progress-font-size: 1em;
$progress-bg: rgba(125, 125, 125, 0.15);
$progress-border-radius: $border-radius;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba($black, 0.1);
$progress-bar-color: $white;
$progress-bar-bg: theme-color('primary');
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width 0.4s ease;
