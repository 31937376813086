@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.scheduling {
  .checkbox-btn {
    margin-bottom: 20px;
  }

  .form__form-group {
    margin-bottom: 20px;
    padding: 0;

    label {
      padding-top: 0;
    }
  }
}
