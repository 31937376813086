@charset "UTF-8";
:export {
  primary: #8B63C1;
  secondary: #888;
  success: #4E70DB;
  info: #2457A7;
  warning: #F5C730;
  danger: #E53322;
  light: #F6F6F6;
  dark: #1C1C21;
  purple: #6621A9;
  yellow: #F5C730;
  orange: #FC723C;
  green: #4E70DB;
  red: #E53322;
  cyan: #8B63C1;
  blue: #2457A7;
  chart-color-1: #8B63C1;
  chart-color-2: #20C997;
  chart-color-3: #4E70DB;
  chart-color-4: #F5C730;
  chart-color-5: #FC723C;
  chart-color-6: #E53322;
  chart-color-7: #E83E8C;
  chart-color-8: #6621A9;
  chart-color-9: #6610F2;
  chart-color-0: #2457A7;
  chart-color-hc-1: #8B63C1;
  chart-color-hc-2: #F5C730;
  chart-color-hc-3: #E83E8C;
  chart-color-hc-4: #6610F2;
  chart-color-hc-5: #20C997;
  chart-color-hc-6: #FC723C;
  chart-color-hc-7: #6621A9;
  chart-color-hc-8: #4E70DB;
  chart-color-hc-9: #E53322;
  chart-color-hc-0: #2457A7; }

.rc-notification {
  height: 0;
  left: auto !important;
  position: fixed;
  right: 20px;
  top: 20px !important;
  z-index: 101; }

.rc-notification-notice-content {
  display: flex; }

.rc-notification-notice-close-x:after {
  content: '×'; }

.rc-notification-fade-enter {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused; }

.rc-notification-fade-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused; }

.rc-notification-fade-enter.rc-notification-fade-enter-active {
  animation-name: rcNotificationFadeIn;
  animation-play-state: running; }
  .rc-notification-fade-enter.rc-notification-fade-enter-active.right-up {
    animation-name: rcNotificationRightFadeIn; }
  .rc-notification-fade-enter.rc-notification-fade-enter-active.left-up {
    animation-name: rcNotificationLeftFadeIn; }

.rc-notification-fade-leave.rc-notification-fade-leave-active {
  animation-name: rcDialogFadeOut;
  animation-play-state: running; }

@keyframes rcNotificationFadeIn {
  0% {
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes rcNotificationLeftFadeIn {
  0% {
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes rcNotificationRightFadeIn {
  0% {
    transform: translateX(100%);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes rcDialogFadeOut {
  0% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    transform: translateY(-100%);
    opacity: 0; } }
