@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.page-toolbar {
  @include gutter-sizing('margin-bottom');

  .search {
    .input-group-prepend {
      padding: 0;
      width: rem(32);
    }

    .search-input {
      @media screen and (max-width: $bp-lg) {
        padding-left: rem(32);
      }
    }
  }

  .filters {
    justify-self: flex-end;
    z-index: 2;

    .collapse-contents {
      @media screen and (max-width: $bp-lg) {
        padding-top: 20px;
      }

      @media screen and (max-width: $bp-md) {
        padding-top: 16px;
      }
    }

    @media screen and (max-width: $bp-md) {
      .filter {
        padding-bottom: 16px;

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }
  }

  .actions {
    justify-self: flex-end;
  }

  @media screen and (max-width: $bp-lg) {
    .create-btn {
      border-radius: 23px;
      height: 46px;
      line-height: 1;
      margin: -4px;
      padding: 0;
      width: 46px;

      .mdi-icon {
        margin: 0;
      }
    }
  }
}
