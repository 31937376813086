@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.breadcrumb-item {
  color: white;
  display: none;
  font-weight: $font-weight-light;

  &:after {
    content: ' / ';
    display: inline-block;
    opacity: 0.5;
    padding-left: 0.5rem;
  }

  a {
    opacity: 0.7;

    &:hover {
      opacity: 0.9;
    }
  }
}
