@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';
@import '~bootstrap/scss/mixins/screen-reader';

.list-field {
  legend {
    border-top: 0;
    border-bottom: 1px solid white;
    font-size: $input-font-size;
    padding: 16px 40px 16px 0;

    @media screen and (min-width: $bp-md) {
      font-size: 14px;
    }
  }

  .btn-add-field {
    margin-top: 20px;
  }

  .single-field {
    border-bottom: 1px solid white;
    position: relative;

    .input-container {
      padding-right: 40px;
    }

    .btn.icon {
      @media screen and (max-width: $bp-md) {
        bottom: 12px;
        margin: 0;
        top: auto;
      }
    }
  }
}
