@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.material-table {
  th {
    white-space: nowrap;
  }

  .material-table__row {
    height: auto;
  }

  .material-table__cell {
    padding: 4px 10px;

    &.thumbnail {
      padding-left: 0;
      padding-right: 0;

      img {
        max-width: 100px;
      }
    }

    &.nobr {
      white-space: nowrap;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    .btn,
    .btn-group {
      margin-bottom: 0;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }

    .badge-pill {
      font-size: 12px;
    }
  }
}

.material-table__toolbar-wrap {
  position: absolute;
  top: 10px;
  right: 0;

  .material-table__toolbar {
    padding: 0 30px;
  }

  .material-table__toolbar-button {
    height: 36px;
    width: 36px;
    padding: 0;
  }
}

.material-table__toolbar-selected {
  margin-right: 10px;
}

.material-table__row {
  transition: 0.3s;
  cursor: pointer;

  &[aria-checked='true'] {
    @include themify($themes) {
      background-color: themed('cardColorHover');
    }

    .material-table__checkbox span {
      color: $color-accent;
    }
  }

  &:hover {
    @include themify($themes) {
      background-color: themed('cardColorHover');
    }
  }
}

.material-table__checkbox--checked span {
  color: $color-accent;
}

.material-table__cell.material-table__cell--sort {
  span {
    transition: 0.3s;

    &:hover {
      color: $color-accent;
    }
  }
}

.material-table__checkbox {
  transition: 0.3s;

  @include themify($themes) {
    color: themed('colorIcon');
  }
}

.material-table__pagination {
  .MuiTablePagination-spacer-3 {
    display: none;
  }

  & > div {
    padding: 0;
  }
}

.material-table__wrap {
  overflow-x: auto;
}

.material-table__filter-menu {
  div:last-child {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }

  .material-table__filter-menu-item {
    color: #646777;
    transition: 0.3s;
    font-size: 13px;
    padding: 7px 24px;
    height: auto;

    &:hover {
      background: #FAFBFE;
    }
  }
}

@media screen and (max-width: 768px) {
  .material-table__pagination {
    div {
      margin-left: 8px;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin: 0;
      }
    }

    div > span:first-of-type {
      display: none;
    }
  }
}

@media screen and (max-width: 420px) {
  .material-table__toolbar-selected {
    span {
      display: none;
    }
  }
}
