@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

$triangle-size: 8px;

.form-row {
  font-size: $input-font-size;

  @media screen and (min-width: $bp-md) {
    font-size: $font-size-base-md-up;
  }

  .help-text {
    color: $text-muted;
  }
}

// Validation

.form__form-group-error {
  background: mix($red, white, 15%);
  border: 1px solid $red;
  border-radius: $border-radius;
  left: 0;
  max-width: 100%;
  padding: $input-btn-padding-y $input-btn-padding-x;
  position: absolute;
  width: auto;

  &:before,
  &:after {
    content: '';
    left: 4px;
    position: absolute;
    z-index: 0;
  }

  &:after {
    z-index: 1;
  }
}

.form__form-group-input-wrap--error-below {
  position: relative;

  .form-control {
    transition: margin-bottom 0.3s ease-in-out;
    will-change: margin-bottom;
  }

  .form__form-group-error {
    top: 100%;
    margin: 4px 0 0;

    &:before {
      border: $triangle-size solid transparent;
      border-bottom: $triangle-size solid $red;
      top: -1 - ($triangle-size * 2);
    }

    &:after {
      border: $triangle-size solid transparent;
      border-bottom: $triangle-size solid mix($red, white, 15%);
      top: 0 - ($triangle-size * 2);
    }
  }

  .is-invalid {
    margin-bottom: 26px;

    @media screen and (min-width: $bp-md) {
      margin-bottom: 17px;
    }
  }
}

.form__form-group-input-wrap--error-above {
  position: relative;

  .form-control {
    transition: margin-bottom 0.3s ease-in-out;
    will-change: margin-bottom;
  }

  .form__form-group-error {
    bottom: 100%;
    margin: 0 0 4px;

    &:before {
      border: $triangle-size solid transparent;
      border-top: $triangle-size solid $red;
      bottom: -1 - ($triangle-size * 2);
    }

    &:after {
      border: $triangle-size solid transparent;
      border-top: $triangle-size solid mix($red, white, 15%);
      bottom: 0 - ($triangle-size * 2);
    }
  }

  .is-invalid {
    margin-top: 26px;

    @media screen and (min-width: $bp-md) {
      margin-top: 17px;
    }
  }
}

// .formRow {

// }
