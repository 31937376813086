// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.75rem;
$table-cell-padding-sm: 0.3rem;

$table-bg: transparent;
$table-accent-bg: rgba($black, 0.05);
$table-hover-bg: rgba($black, 0.075);
$table-active-bg: $table-hover-bg;

$table-border-width: $border-width;
$table-border-color: $gray-300;

$table-head-bg: $gray-200;
$table-head-color: $gray-700;

$table-dark-bg: $gray-900;
$table-dark-accent-bg: rgba($white, 0.05);
$table-dark-hover-bg: rgba($white, 0.075);
$table-dark-border-color: lighten($gray-900, 7.5%);
$table-dark-color: $body-bg;

$table-striped-order: odd;

$table-caption-color: $text-muted;
