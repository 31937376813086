@charset "UTF-8";
:export {
  primary: #8B63C1;
  secondary: #888;
  success: #4E70DB;
  info: #2457A7;
  warning: #F5C730;
  danger: #E53322;
  light: #F6F6F6;
  dark: #1C1C21;
  purple: #6621A9;
  yellow: #F5C730;
  orange: #FC723C;
  green: #4E70DB;
  red: #E53322;
  cyan: #8B63C1;
  blue: #2457A7;
  chart-color-1: #8B63C1;
  chart-color-2: #20C997;
  chart-color-3: #4E70DB;
  chart-color-4: #F5C730;
  chart-color-5: #FC723C;
  chart-color-6: #E53322;
  chart-color-7: #E83E8C;
  chart-color-8: #6621A9;
  chart-color-9: #6610F2;
  chart-color-0: #2457A7;
  chart-color-hc-1: #8B63C1;
  chart-color-hc-2: #F5C730;
  chart-color-hc-3: #E83E8C;
  chart-color-hc-4: #6610F2;
  chart-color-hc-5: #20C997;
  chart-color-hc-6: #FC723C;
  chart-color-hc-7: #6621A9;
  chart-color-hc-8: #4E70DB;
  chart-color-hc-9: #E53322;
  chart-color-hc-0: #2457A7; }

.activity {
  display: flex; }
  @media screen and (min-width: 0) {
    .activity {
      margin-bottom: 16px; } }
  @media screen and (min-width: 760px) {
    .activity {
      margin-bottom: 20px; } }
  @media screen and (min-width: 1200px) {
    .activity {
      margin-bottom: 30px; } }
  .activity .thumbnail {
    box-shadow: inset 0 -1px 3px rgba(0, 0, 0, 0.3);
    height: 36px;
    margin-right: 10px;
    margin-top: 1px;
    width: 36px; }
  .activity .action {
    color: #555;
    display: block; }
  .activity .name,
  .activity .timestamp {
    display: inline;
    font-size: 1em; }
  .activity .name {
    font-weight: 700; }
    .activity .name:after {
      content: ' • '; }
  .activity .more-info {
    color: #555;
    margin-top: 0.5em; }
