.btn-toolbar {
  margin-top: $spacer;

  & > .btn {
    margin-right: $spacer;
    margin-bottom: $spacer;

    &:last-child {
      margin-right: 0;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &.btn-toolbar--center {
    & > * {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.btn-group {
  @include themify($themes) {
    background-color: themed('cardColorHover');
  }

  .btn {
    margin-right: 0;
  }

  &.justified {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .btn {
      width: 100%;
    }
  }

  &.icons {
    .btn {
      padding: 7px 8px;
      line-height: 14px;
    }
  }

  &.open .dropdown-toggle {
    box-shadow: none;
  }
}

.unstyled-button {
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;
}
