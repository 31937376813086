@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.collapse-container {
  .title {
    margin: 0;
  }

  .icon {
    position: absolute;
    right: 0;
    transition: transform $transition-duration ease-in-out;
    transform: translate(-50%, -50%) rotate(0deg);
    top: 50%;
  }

  .open .icon {
    transform: translate(-50%, -50%) rotate(180deg);
  }

  .collapse-content {
    padding-top: $spacer;
  }
}
