html {
  // Base font size set as a percentage
  // 100% usually = 16px, but allows users to adjust default font size
  background-color: #2D2D2F;
  color: white;
  font-family: $font-family-sans-serif;
  font-size: ($rem-value * 1px); //  Rem value in px
  font-weight: 400;
  line-height: 1.6;
}

body {
  background-color: #2D2D2F;
  font-family: $font-family-sans-serif;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll !important; // Important to overwrite Material Tables inline styles
  padding: 0 !important;
}

main {
  padding: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: $font-family-sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

ul,
ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $color-cyan;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-cyan-hover;
  }
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

.btn-group-md.btn-group .btn {
  line-height: 22px;
  padding: 12px 15px;
}

.filter-label {
  font-size: 16px;
  margin: 6px 00;
}

label[for] {
  cursor: pointer;
}

.thumbnail-container {
  position: relative;

  &.blank-slate {
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    opacity: 0.5;
    padding: 4px;
    text-align: center;
  }

  .duration {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    bottom: 2px;
    color: white;
    font-size: 11px;
    left: 2px;
    line-height: 1;
    padding: 2px 6px;
    position: absolute;
  }
}

.modal-image {
  margin: auto;
  max-width: 600px;
}

button.modal-close {
  background: white;
  border-radius: 50px;
  height: 32px;
  right: 10px;
  position: absolute;
  top: 10px;
  width: 32px;

  span {
    color: black;
    position: relative;
    top: -2px;
  }
}

// Shadow DOM Styles

::selection {
  background: $primary;
  color: #FFF;
}

// ::-webkit-scrollbar {
//   background-color: #AABCFF;
//   box-shadow: -1px 1px 7px rgba(black, 0.15) inset;
//   height: 8px;
//   padding: 1px;
//   width: 8px;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 0;
//   background-color: #5E7CE2;
//   // background-color: rgba(white, 0.5);
// }

// Resetting x-overflow on material tables

[style='overflow-x: auto;'] {
  &::-webkit-scrollbar {
    background-color: initial;
    box-shadow: initial;
    height: initial;
    padding: initial;
    width: initial;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: initial;
    background-color: initial;
  }
}
