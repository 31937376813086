@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.blank-slate {
  align-items: center;
  border: 1px solid $border-color-light;
  box-shadow: none;
  justify-content: center;

  &.card {
    margin-bottom: 0;
  }

  .card-title,
  .card-text,
  .card-body {
    text-align: center;
  }
}
