// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem;
$dropdown-padding-y: 0.5rem;
$dropdown-spacer: 0.125rem;
$dropdown-bg: $white;
$dropdown-border-color: rgba($black, 0.15);
$dropdown-border-radius: $border-radius;
$dropdown-border-width: $border-width;
$dropdown-divider-bg: $gray-200;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175);

$dropdown-link-color: $gray-900;
$dropdown-link-hover-color: darken($gray-900, 5%);
$dropdown-link-hover-bg: $gray-100;

$dropdown-link-active-color: $component-active-color;
$dropdown-link-active-bg: $component-active-bg;

$dropdown-link-disabled-color: $gray-600;

$dropdown-item-padding-y: 0.25rem;
$dropdown-item-padding-x: 1.5rem;

$dropdown-header-color: $gray-600;
