$rootPath: '../';

.account {
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  background: linear-gradient(313.36deg, #07BEB8 -29.38%, #8D61C3 102.13%), linear-gradient(238.24deg, rgba(204, 194, 234, 0.8) -2.41%, rgba(119, 133, 210, 0.8) 100%), #6A5DDB;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include themify($themes) {
    background-color: themed('colorBackgroundBody');
  }

  // .theme-lightondark & {
  //   background: linear-gradient(313.36deg, #07BEB8 -29.38%, #8D61C3 102.13%), linear-gradient(238.24deg, rgba(204, 194, 234, 0.8) -2.41%, rgba(119, 133, 210, 0.8) 100%), #6A5DDB;
  //   background-position: center;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  // }

  &.account--photo {
    background-size: cover;

    .account__card {
      background-color: rgba(0, 10, 16, 0.8);
      color: #FFF;
    }

    .form__form-group-icon,
    .form__form-group-button {
      background: transparent;
    }

    .form__form-group-icon,
    input:not(:last-child) {
      border-right: none;
    }

    .form__form-group-button,
    input {
      border-left: none;
    }

    .form__form-group-button.active {
      border-color: #EFF1F5;

      svg {
        fill: $color-accent;
      }
    }

    input {
      color: #FFF;

      &:focus {
        border-color: #EFF1F5;
      }
    }

    p {
      color: #FFF;
    }

    .account__title {
      color: #FFF;
    }
  }
}

.account__wrapper {
  margin: auto;
}

.account__card {
  @include gutter-sizing([ 'padding' ], 2);
  background-color: #FFF;
  max-width: 520px;
  width: 100%;

  // @include themify($themes) {
  //   background-color: themed('cardBackground');
  // }
}

.account__logo {
  .disney__logo {
    display: block;
    margin: 0 auto;
    max-width: 66%;

    @media screen and (min-width: $bp-md) {
      margin: 0 auto 20px;
      max-width: 246px;
    }
  }
}

.account__logo-accent {
  color: $color-blue;
}

.account__have-account {
  text-align: center;
  margin-top: 20px;

  a {
    color: $color-blue;
    transition: all 0.3s;

    &:hover {
      color: $color-blue-hover;
      text-decoration: none;
    }
  }
}
