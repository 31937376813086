@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

$input-height: 6rem;

.asset-field {
  .dropzone,
  .dropzone.dropzone--single,
  .dropzone.dropzone--multiple {
    height: auto;
    min-height: $input-height;
    margin-bottom: $spacer;

    @media screen and (min-width: $bp-md) {
      margin-bottom: 0;
    }
  }

  .select-from-assets {
    border-color: $border-color;
    height: auto;
    min-height: $input-height;
  }
}
