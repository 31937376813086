// Code

$code-font-size: 87.5%;
$code-color: $pink;

$kbd-padding-y: 0.2rem;
$kbd-padding-x: 0.4rem;
$kbd-font-size: $code-font-size;
$kbd-color: $white;
$kbd-bg: $gray-900;

$pre-color: $gray-900;
$pre-scrollable-max-height: 340px;
