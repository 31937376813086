.card {
  border: 0;
  box-shadow: $box-shadow;
  font-family: $font-family-sans-serif !important;

  &.clip {
    overflow: hidden;
  }

  @include themify($themes) {
    background-color: #FFF;
  }

  &.main-content-card {
    border-radius: 0 !important;
    box-shadow: none !important;
    background-color: #FFF;
    color: #25282A;
  }

  .card {
    @include themify($themes) {
      background-color: #6476CC;
      color: #FFF;
    }
  }

  // Making sure the header border still shows up

  &.bg-light .card-header,
  &.bg-warning .card-header {
    border-color: rgba($black, 0.075);
  }

  &.top-border {
    border-top: $border-radius-lg solid;
  }

  &.panel .card-title {
    margin-top: 2px; // Makes uppercase letters look closer to center
    text-transform: uppercase;
    position: relative;
  }
}

// Removing additional padding added by Bootstrap

.card-img-overlay {
  padding: 0;
}

// Fixing body padding

.card-body {
  @include gutter-sizing('padding');
}

.card-header,
.card-footer,
.card-header + .card-body {
  @include gutter-sizing([ 'padding-bottom', 'padding-top' ], 0.5);
  @include gutter-sizing([ 'padding-left', 'padding-right' ]);
}

.review {
  padding: 40px 60px;

  h3 {
    width: 100%;
  }

  h4 {
    font-size: 1rem;
    font-weight: 500;
  }

  a {
    color: #4E70DB;
  }

  .col,
  .col-6,
  .col-12,
  .col-md-4,
  .col-md-3,
  .col-4,
  .col-8 {
    margin-top: 20px;
  }

  hr {
    margin: 50px 0 !important;
    border-color: #B9BFC6;
  }
}

.applications-started-card {
  .card {
    background-color: #8D61C3 !important;
  }
}

.applications-completed-card {
  .card {
    background-color: #80BA4C !important;
  }
}

.spacing-top {
  margin-top: 60px;
}
