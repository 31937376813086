// Spacing
//
// Control the styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variabl
$spacers: map-merge((
  0: 0,
  1: (
    $spacer * 0.25
  ),
  2: (
    $spacer * 0.5
  ),
  3: $spacer,
  4: (
    $spacer * 1.5
  ),
  5: (
    $spacer * 3
  )
), $spacers);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
// stylelint-disable-next-line scss/dollar-variabl
$sizes: map-merge((
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto
), $sizes);

$border-width: 1px;
$border-radius: 0.25rem;
$border-radius-lg: 0.4rem;
$border-radius-sm: 0.2rem;

$caret-width: 0.3em;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: rem(20);
$font-size-sm: rem(12);
$font-size-xs: rem(10);

$input-btn-padding-y: rem(6);
$input-btn-padding-x: rem(12);
$input-btn-font-family: null;
$input-btn-font-size: rem(12);
$input-btn-line-height: $line-height-base;
// additional var for responsive font sizing
$font-size-base-md-up: $input-btn-font-size;

$input-btn-focus-width: 0.2rem;
$input-btn-focus-color: rgba($component-active-bg, 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.2rem;
$input-btn-padding-x-sm: 0.75rem;
$input-btn-font-size-sm: $font-size-sm;
$input-btn-line-height-sm: $line-height-sm;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1.5rem;
$input-btn-font-size-lg: $font-size-base;
$input-btn-line-height-lg: $line-height-base;

$input-btn-border-width: $border-width;
