// Brand colors
$purple: #6621A9;
$yellow: #F5C730;
$orange: #FC723C;
$green: #4E70DB;
$red: #E53322;
$cyan: #8B63C1;
$blue: #2457A7;

$pink: #E83E8C;
$indigo: #6610F2;
$teal: #20C997;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// EasyDev variables
$color-accent: $green;
$color-accent-hover: darken($color-accent, $theme-color-interval);

$color-yellow: $yellow;
$color-yellow-hover: darken($color-yellow, $theme-color-interval);

$color-purple: $purple;
$color-purple-hover: darken($color-purple, $theme-color-interval);

$color-red: $red;
$color-red-hover: darken($color-red, $theme-color-interval);

$color-blue: $blue;
$color-blue-hover: darken($color-blue, $theme-color-interval);

$color-cyan: $cyan;
$color-cyan-hover: darken($color-cyan, $theme-color-interval);

// Grays
$background-gray-dark-1: #1C1C21;
$background-gray-dark-2: #232329;
$background-gray-dark-3: #2D2D2F;

$background-gray-light-1: #F6F6F6;
$background-gray-light-2: #999;
$background-gray-light-3: #444;

$border-color: $background-gray-light-2;
$border-color-light: mix($border-color, white, 50%);
$border-color-dark: $background-gray-light-3;

$text-color: #333;
$text-color-medium: #555;
$text-color-muted: #888;

// Mapping for EasyDev variables
$color-additional: $background-gray-light-2;
$color-additional-hover: darken($color-additional, $theme-color-interval);

$color-gray: $color-additional;
$color-aside-background: $background-gray-dark-2;

$color-border: $border-color;
$color-border-dark: $background-gray-light-3;

$themes: (
  light: (
    topbarBackground: white,
    colorBackground: white,
    colorBackgroundBody:
    lighten($background-gray-light-1, $theme-color-interval),
    colorText: $text-color-muted,
    colorTextAdditional: lighten($text-color-muted, $theme-color-interval),
    logoImg: 'img/logo/launchpad-logo-blue.svg',
    colorHover: $background-gray-light-1,
    colorBorder: $background-gray-light-1,
    colorIcon: $background-gray-light-2,
    imgInvert: invert(0%),
    colorFieldsBorder: #F2F4F7,
    colorBubble: rgba($background-gray-light-2, 0.65),
    colorBubbleActive: rgba($background-gray-light-2, 0.6),
    colorScrollbar: $background-gray-light-2,
    colorFitness: $background-gray-light-3,
    cardBackground: white,
    cardColorHover: $background-gray-light-1,
    cardText: $text-color-muted,
    furnitureTextColor: $text-color-muted
  ),
  dark: (
    topbarBackground: $background-gray-dark-1,
    colorBackground: $background-gray-dark-2,
    colorBackgroundBody: $background-gray-dark-3,
    colorText: white,
    colorTextAdditional: $background-gray-dark-2,
    logoImg: 'img/logo/launchpad-logo-white.svg',
    colorHover: $background-gray-dark-3,
    colorBorder: $background-gray-dark-3,
    colorIcon: $purple,
    imgInvert: invert(100%),
    colorFieldsBorder: $background-gray-light-3,
    colorBubble: rgba($background-gray-dark-2, 0.65),
    colorBubbleActive: rgba($background-gray-dark-2, 0.6),
    colorScrollbar: $background-gray-dark-2,
    colorFitness: white,
    cardBackground: $background-gray-dark-2,
    cardColorHover: $background-gray-dark-2,
    cardText: white,
    furnitureTextColor: white
  ),
  lightondark: (
    topbarBackground: $background-gray-dark-1,
    colorBackground: $background-gray-dark-2,
    colorBackgroundBody: $background-gray-dark-3,
    colorText: white,
    colorTextAdditional: $background-gray-light-2,
    logoImg: 'img/logo/launchpad-logo-white.svg',
    colorHover: $background-gray-dark-2,
    colorBorder: $border-color,
    colorIcon: $color-blue,
    imgInvert: invert(0%),
    colorFieldsBorder: $color-border,
    colorBubble: rgba($background-gray-light-2, 0.65),
    colorBubbleActive: rgba($background-gray-light-2, 0.6),
    colorScrollbar: $background-gray-light-1,
    colorFitness: white,
    cardBackground: $background-gray-light-1,
    cardColorHover: white,
    cardText: $text-color,
    furnitureTextColor: white
  )
);

// Bootstrap variables ---------------------------------------------------------------------------------

// Color system
$white: #FFF;
$gray-100: #F8F9FA;
$gray-200: #E9ECEF;
$gray-300: #DEE2E6;
$gray-400: #CED4DA;
$gray-500: #ADB5BD;
$gray-600: #6C757D;
$gray-700: #495057;
$gray-800: #343A40;
$gray-900: #212529;
$black: #000;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge((
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900
), $grays);

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge((
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'white': $white,
  'gray': $gray-600,
  'gray-dark': $gray-800
), $colors);

$primary: $cyan;
$secondary: $text-color-muted;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red;
$light: $white;
$dark: $background-gray-dark-3;

$primary-lighter: lighten($cyan, $theme-color-interval);
$secondary-lighter: lighten($text-color-muted, $theme-color-interval);
$success-lighter: lighten($green, $theme-color-interval);
$info-lighter: lighten($blue, $theme-color-interval);
$warning-lighter: lighten($yellow, $theme-color-interval);
$danger-lighter: lighten($red, $theme-color-interval);
$light-lighter: lighten($background-gray-light-1, $theme-color-interval);
$dark-lighter: lighten($background-gray-dark-1, $theme-color-interval);

$primary-darker: darken($cyan, $theme-color-interval);
$secondary-darker: darken($text-color-muted, $theme-color-interval);
$success-darker: darken($green, $theme-color-interval);
$info-darker: darken($blue, $theme-color-interval);
$warning-darker: darken($yellow, $theme-color-interval);
$danger-darker: darken($red, $theme-color-interval);
$light-darker: darken($background-gray-light-1, $theme-color-interval);
$dark-darker: darken($background-gray-dark-1, $theme-color-interval);

// For React-Select
$success-75: mix($green, white, 25%);
$success-50: mix($green, white, 50%);
$success-25: mix($green, white, 75%);

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge((
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark
), $theme-colors);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 180;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $text-color;
$yiq-text-light: $white;

// Body
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $gray-900;

$component-active-color: $white;
$component-active-bg: theme-color('success');

// Chart colors
$chart-color-1: #002E6D;
$chart-color-2: #49C6E8;
$chart-color-3: #20C997;
$chart-color-4: #8FC540;
$chart-color-5: #F5C730;
$chart-color-6: #FC723C;
$chart-color-7: #DC3545;
$chart-color-8: #E83E8C;
$chart-color-9: #6621A9;
$chart-color-10: #6610F2;

// Export for js...
// import variables from 'scss/1-settings/colors.scss';

:export {
  // Priority colors
  primary: $cyan;
  secondary: $text-color-muted;
  success: $green;
  info: $blue;
  warning: $yellow;
  danger: $red;
  light: $background-gray-light-1;
  dark: $background-gray-dark-1;
  // Brand colors
  purple: $purple;
  yellow: $yellow;
  orange: $orange;
  green: $green;
  red: $red;
  cyan: $cyan;
  blue: $blue;
  // Chart colors (low contrast)
  chart-color-1: $cyan;
  chart-color-2: $teal;
  chart-color-3: $green;
  chart-color-4: $yellow;
  chart-color-5: $orange;
  chart-color-6: $red;
  chart-color-7: $pink;
  chart-color-8: $purple;
  chart-color-9: $indigo;
  chart-color-0: $blue;
  // Chart colors (high contrast)
  chart-color-hc-1: $cyan;
  chart-color-hc-2: $yellow;
  chart-color-hc-3: $pink;
  chart-color-hc-4: $indigo;
  chart-color-hc-5: $teal;
  chart-color-hc-6: $orange;
  chart-color-hc-7: $purple;
  chart-color-hc-8: $green;
  chart-color-hc-9: $red;
  chart-color-hc-0: $blue;
}
